import { classNames } from "../../utils"
import { IconProps } from "../index"

export const UserIcon = ({ className, style }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" style={style} className={classNames(className, "w-[24px] h-[24px]")} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5948 10.9319C19.1972 10.9319 20.497 9.63295 20.497 8.03061C20.497 6.42828 19.1972 5.12936 17.5948 5.12936" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.9291 14.0847C19.4076 14.1177 19.8833 14.1856 20.3517 14.291C21.0026 14.4184 21.7854 14.6852 22.0641 15.2691C22.2419 15.6431 22.2419 16.0785 22.0641 16.4534C21.7863 17.0373 21.0026 17.3032 20.3517 17.437" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.28998 10.9319C4.68765 10.9319 3.38782 9.63295 3.38782 8.03061C3.38782 6.42828 4.68765 5.12936 6.28998 5.12936" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.95577 14.0847C4.47727 14.1177 4.00152 14.1856 3.5331 14.291C2.88227 14.4184 2.09943 14.6852 1.82168 15.2691C1.64293 15.6431 1.64293 16.0785 1.82168 16.4534C2.09852 17.0373 2.88227 17.3032 3.5331 17.437" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9378 14.7096C15.1846 14.7096 17.9584 15.2009 17.9584 17.1671C17.9584 19.1325 15.2029 19.6421 11.9378 19.6421C8.69003 19.6421 5.91711 19.1508 5.91711 17.1846C5.91711 15.2183 8.67261 14.7096 11.9378 14.7096Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9377 11.9049C9.79638 11.9049 8.07947 10.188 8.07947 8.04575C8.07947 5.90442 9.79638 4.1875 11.9377 4.1875C14.0791 4.1875 15.796 5.90442 15.796 8.04575C15.796 10.188 14.0791 11.9049 11.9377 11.9049Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}